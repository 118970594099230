<template>
  <div class="flex flex-col w-full mt-6 px-15 lg:px-7 sm-ws:px-10">
    <page-title>Révélation potentiel</page-title>
    <table-title nextPath="ajouter">
      <template v-slot:title
        >Révélation potentiel sur AdopteMonTerrain</template
      >
      <template slot="button" slot-scope="{ nextPath }">
        <div
          @click.prevent="nextPath('revelation-list')"
          class="flex items-center justify-between px-4 py-2 text-xs font-semibold tracking-wide text-white uppercase transition-colors duration-200 border-2 rounded-full cursor-pointer hover:bg-white hover:text-promy-green-300 border-promy-green-300 w-42 bg-promy-green-300 font-main"
        >
          Retour à la liste
        </div>
      </template>
    </table-title>
    <hr class="my-6" />
    <div v-if="revelations" class="grid grid-cols-3 lg:grid-cols-1 gap-4">
      <div class="flex flex-col space-y-1">
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Adresse du terrain :
        </div>
        <div class="text-promy-gray-750">
          {{ revelations.informations.adresse }}
        </div>
      </div>
      <div class="flex flex-col space-y-1">
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Réf cadastral :
        </div>
        <div class="text-promy-gray-750">
          {{ getRefCadastraleParcelles() }}
        </div>
      </div>
      <div class="flex flex-col space-y-1">
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Résidence :
        </div>
        <div class="text-promy-gray-750">
          {{ revelations.informations.residence }}
        </div>
      </div>
      <div class="flex flex-col space-y-1">
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Superficie totale du terrain :
        </div>
        <div class="text-promy-gray-750">
          {{ revelations.informations.superficie_totale }}m²
        </div>
      </div>
      <div class="flex flex-col space-y-1">
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Surface construite du bien :
        </div>
        <div class="text-promy-gray-750">
          {{ revelations.informations.superficie_construite }}m²
        </div>
      </div>
      <div
        v-if="revelations.informations.pieces"
        class="flex flex-col space-y-1"
      >
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Nombre des pièces :
        </div>
        <div class="text-promy-gray-750">
          {{ revelations.informations.pieces }}
        </div>
      </div>
      <div
        class="flex flex-col space-y-1"
        v-if="
          revelations.informations.zonage_plu &&
          revelations.informations.zonage_plu.length
        "
      >
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Zonages :
        </div>
        <div class="flex flex-col space-y-1">
          <div
            v-for="(zone, index) in revelations.informations.zonage_plu"
            :key="index"
            class="text-promy-gray-750"
          >
            - <strong>{{ zone.properties.libelle }}</strong> :
            {{ zone.properties.libelong }}
          </div>
        </div>
      </div>
      <div
        v-if="revelations.informations.etat_bien"
        class="flex flex-col space-y-1"
      >
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          L'état du bien :
        </div>
        <div
          v-if="revelations.informations.etat_bien === 'autre'"
          class="text-promy-gray-750"
        >
          {{ revelations.informations.other_etat_bien }}
        </div>
        <div v-else class="text-promy-gray-750">
          {{ revelations.informations.etat_bien }}
        </div>
      </div>
      <div class="flex flex-col space-y-1">
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Le révélateur du potentiel est :
        </div>
        <div class="text-promy-gray-750">
          {{ revelations.informations.label_profile }}
        </div>
      </div>
      <div class="flex flex-col space-y-1">
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Le révélateur souhaite vendre le bien ?
        </div>
        <div class="text-promy-gray-750">
          {{ revelations.informations.on_sale }}
        </div>
      </div>
      <div
        v-if="revelations.informations.is_estimated"
        class="flex flex-col space-y-1"
      >
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Ce bien est déjà estimé ?
        </div>
        <div class="text-promy-gray-750">
          {{ revelations.informations.is_estimated }}
        </div>
      </div>
      <div
        v-if="revelations.informations.estimated_price"
        class="flex flex-col space-y-1"
      >
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Selon vous, combien vaut votre bien ?
        </div>
        <div class="text-promy-gray-750">
          {{ revelations.informations.estimated_price | formatEUR }}
        </div>
      </div>

      <div
        v-if="revelations.informations.has_neighbor_or_otherOwner"
        class="flex flex-col space-y-1"
      >
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Avez-vous des voisins intéressés par un projet de vente ?
        </div>
        <div
          class="text-promy-gray-750"
          v-if="revelations.informations.has_neighbor_or_otherOwner === 'Non'"
        >
          Non
        </div>
        <div class="text-promy-gray-750" v-else>
          <div
            class="flex items-center justify-between"
            v-for="neighbors in revelations.informations
              .neighbors_or_otherOwners"
            :key="neighbors"
          >
            <div>
              <p>Adresse :</p>
              <p>{{ neighbors.adresse.label }}</p>
            </div>
            <div>
              <p>Nom :</p>
              <p>{{ neighbors.nom }}</p>
            </div>
            <div>
              <p>Prenom :</p>
              <p>{{ neighbors.prenom }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col space-y-1">
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Type du bien:
        </div>
        <div
          v-if="revelations.informations.type_bien === 'Autre'"
          class="text-promy-gray-750"
        >
          {{ revelations.informations.other_type_bien }}
        </div>
        <div v-else class="text-promy-gray-750">
          {{ revelations.informations.type_bien }}
        </div>
      </div>
      <div class="flex flex-col space-y-1">
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Êtes-vous déjà en contact avec un promoteur/constructeur ?
        </div>
        <div class="text-promy-gray-750">
          {{ revelations.informations.is_contact_with_promoteur }}
        </div>
      </div>
      <div
        v-if="revelations.informations.has_other_bien"
        class="flex flex-col space-y-1"
      >
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Êtes-vous propriétaire d'un autre bien ?
        </div>
        <div class="text-promy-gray-750">
          {{ revelations.informations.has_other_bien }}
        </div>
      </div>
      <div
        v-if="revelations.informations.has_bien_constructible"
        class="flex flex-col space-y-1"
      >
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Est-ce un bien constructible ?
        </div>
        <div
          v-if="revelations.informations.has_bien_constructible === 'Non'"
          class="text-promy-gray-750"
        >
          {{ revelations.informations.has_bien_constructible }}
        </div>
        <div v-else class="text-promy-gray-750">
          {{ revelations.informations.autre_bien_constructible }}
        </div>
      </div>
      <div
        v-if="revelations.informations.more_informations"
        class="flex flex-col space-y-1"
      >
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Informations complémentaires:
        </div>
        <div class="text-promy-gray-750">
          {{ revelations.informations.more_informations }}
        </div>
      </div>
      <div
        v-if="revelations.informations.distance_projet_promoteur_autour"
        class="flex flex-col space-y-1"
      >
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          les projets de vente autour
        </div>
        <div class="text-promy-gray-750">
          {{ revelations.informations.distance_projet_promoteur_autour }}
        </div>
      </div>
    </div>
    <div v-if="revelations" class="my-6">
      <div class="font-extrabold font-main text-xl text-promy-gray-750 mb-2">
        Infos contact du révélateur:
      </div>
      <div class="grid grid-cols-3 gap-4">
        <div class="flex flex-col space-y-1">
          <div class="font-main font-extrabold text-lg text-promy-gray-700">
            Apprenons à nous connaître. Qui êtes vous ?
          </div>
          <div
            v-if="revelations.informations.label_profile === 'autre'"
            class="text-promy-gray-750"
          >
            {{ revelations.informations.other_label_profile }}
          </div>
          <div v-else class="text-promy-gray-750">
            {{ revelations.informations.label_profile }}
          </div>
        </div>
        <div class="flex flex-col space-y-1">
          <div class="font-main font-extrabold text-lg text-promy-gray-700">
            Nom:
          </div>
          <div class="text-promy-gray-750">
            {{ revelations.nom }}
          </div>
        </div>
        <div class="flex flex-col space-y-1">
          <div class="font-main font-extrabold text-lg text-promy-gray-700">
            Prénom:
          </div>
          <div class="text-promy-gray-750">
            {{ revelations.prenom }}
          </div>
        </div>
        <div class="flex flex-col space-y-1">
          <div class="font-main font-extrabold text-lg text-promy-gray-700">
            Civilité:
          </div>
          <div
            v-if="revelations.civilite === 'Autre'"
            class="text-promy-gray-750"
          >
            {{ revelations.informations.other_civilite }}
          </div>
          <div v-else class="text-promy-gray-750">
            {{ revelations.civilite }}
          </div>
        </div>
        <div class="flex flex-col space-y-1">
          <div class="font-main font-extrabold text-lg text-promy-gray-700">
            Email:
          </div>
          <div class="text-promy-gray-750">
            {{ revelations.email }}
          </div>
        </div>
        <div class="flex flex-col space-y-1">
          <div class="font-main font-extrabold text-lg text-promy-gray-700">
            Téléphone mobile:
          </div>
          <div class="text-promy-gray-750">
            {{ revelations.tel }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      revelations: null,
    }
  },
  created() {
    this.getRevelations()
  },

  methods: {
    getRefCadastraleParcelles() {
      return [
        this.revelations.informations.current_parcelle,
        ...this.revelations.informations.parcelles_voisines,
      ]
        .map(
          (parcelle) =>
            parcelle.properties.section + '' + parcelle.properties.numero,
        )
        .join(', ')
    },
    getRevelations() {
      this.$http
        .get('admin/reveler-potentiel/' + this.$route.params.id)
        .then((res) => {
          this.revelations = res.data
        })
    },
  },
}
</script>
